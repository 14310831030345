import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import dateFormat from 'dateformat';
import { useNavigate } from 'react-router-dom';
import { toasts } from 'ui';

import { axiosVidhanPrivate } from '../../../api/axios';
import { useAxios } from '../../../hooks';
import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import { updateWidgetState } from '../../../pages/Home/components/sub-components/UpdateWidgetState';
import {
  SocialLoggerConfig,
  getRedirectUrl,
  getResponseErrorMessage,
} from '../../../utils/common';
import { envMap, userLoggedIn } from '../../../utils/constant';
import type { CustomAxiosError } from '../../../utils/response/types';
import { useAuth } from '../index';
import type { AccessToken, RefreshToken } from '../index';

export type SigninRequestPayload = {
  tokenId: string;
  source: Record<string, string>;
  token?: string;
};

type GoogleSigninResponse = {
  data: {
    newUser: boolean;
    accessToken: AccessToken;
    refreshToken: RefreshToken;
    workspaceUUID: string;
    userUUID: string;
  };
};

const logEvent = async (type: string, newUser: boolean, userUUID: string) => {
  const apiConfig: Record<string, any> = { ...SocialLoggerConfig };
  const fid = window.localStorage.getItem('nec_fid') ?? '';
  apiConfig.payload.params.loginDetails = {
    fid,
    mode: type,
    state: 'COMPLETED',
    timeStamp: dateFormat(new Date(), 'dd/mm/yyyy HH:MM:ss'),
    path: window.location.href,
    newUser: newUser.toString(),
    userUUID,
  };
  apiConfig.payload.params.fid = fid;

  if (envMap.VITE_ENVIRONMENT === 'production') {
    await axiosVidhanPrivate.post(apiConfig.url, apiConfig.payload);
  }
};

export function useSocialSignin(type: string) {
  const navigate = useNavigate();
  const { axios } = useAxios();
  const { setAuth } = useAuth();

  const { sendEventToGTM } = useSendEventToGTM();

  const { mutate: signinWithSocial } = useMutation({
    mutationFn: async (request: SigninRequestPayload) => {
      return await axios.post(`/auth/${type}/login`, request, {
        headers: {
          'Nected-User-IP': localStorage.getItem('Nected-User-IP') ?? '',
          'X-CSRF-Token': localStorage.getItem('X-CSRF-Token') ?? '',
        },
      });
    },
    onSuccess(response: AxiosResponse<GoogleSigninResponse>) {
      const { data } = response.data;
      const { accessToken, refreshToken, newUser, workspaceUUID, userUUID } =
        data;

      const path = newUser ? 'signup' : 'signin';

      void logEvent(type, newUser, userUUID);

      sendEventToGTM({
        event: path,
        status: 'success',
        provider: type,
        userid: userUUID,
        wsid: workspaceUUID,
      });

      sendEventToGTM({
        event: `${path}_success`,
        userid: userUUID,
      });

      const state = userLoggedIn;
      setAuth({ accessToken, refreshToken, workspaceUUID, userUUID });

      if (newUser) {
        navigate('/questionnaire');
      } else {
        const to = getRedirectUrl(window.location.href) ?? '/home';
        try {
          const url = new URL(to);
          window.open(url, '_self');
        } catch (error) {
          navigate(to, { replace: true });
        }
      }

      setTimeout(
        () => {
          updateWidgetState(state, userUUID)
            .then((res) => {})
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        },
        100,
        state,
        userUUID
      );
    },
    onError(error: AxiosError<CustomAxiosError>) {
      toasts.error(getResponseErrorMessage(error), 'error');

      const { code } = error.response?.data ?? { code: '' };

      sendEventToGTM({
        event: 'signup',
        status: 'error',
        reason: code,
        provider: type,
      });
    },
  });

  return {
    signinWithSocial,
  };
}
