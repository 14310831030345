import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import {
  JsonTableViewHeaderDataType,
  JsonTableViewRowDataType,
  getDataTypeNected,
} from 'ui';

import { convertArrayToString } from '../../utils/common';
import { TableDetailType } from './JsonInTableView';

const checkIfListIsHomogenous = (header: JsonTableViewHeaderDataType[]) => {
  if (header.length === 0) {
    // Empty list is considered homogeneous
    return true;
  }

  // Get the dataType of the first element
  const firstDataType = header[0].dataType;

  // Check if all elements have the same dataType as the first one
  return header.every((item) => item.dataType === firstDataType);
};

export const getDetailsForTableView = (
  value: any,
  dataType: string,
  parentKey = ''
) => {
  const tableDetails: TableDetailType = {
    header: [],
    row: [],
    isHomogeneous: false,
  };

  if (_isNil(value)) {
    tableDetails.header = [
      {
        name: parentKey,
        dataType: 'generic',
      },
    ];

    tableDetails.row.push({
      [parentKey]: {
        value,
        dataType,
      },
    });

    return tableDetails;
  }

  if (dataType === 'json') {
    const currentRow: JsonTableViewRowDataType = {};

    Object.keys(value).forEach((key) => {
      tableDetails.header.push({
        name: key,
        dataType: getDataTypeNected(value[key]),
      });

      currentRow[key] = {
        value: value[key],
        dataType: getDataTypeNected(value[key]),
      };
    });

    tableDetails.row.push(currentRow);
  } else if (dataType === 'list') {
    if (!_isNil(value) && !_isEmpty(value)) {
      const listTableDetails: TableDetailType[] = value.map((obj: any) => {
        const firstValueDatatype = getDataTypeNected(obj);

        return getDetailsForTableView(obj, firstValueDatatype, parentKey);
      });

      const combinedHeaders = listTableDetails.flatMap((table) => table.header);

      const uniqueHeaders = Array.from(
        new Map(
          combinedHeaders.map((header) => [JSON.stringify(header), header])
        ).values()
      );

      tableDetails.header = uniqueHeaders;

      if (
        checkIfListIsHomogenous(tableDetails.header) &&
        !(listTableDetails?.[0].isHomogeneous ?? true)
      ) {
        const rowData = listTableDetails.map(
          (currentDetail) => currentDetail.row?.[0]?.[parentKey].value
        );

        tableDetails.row.push({
          [parentKey]: {
            value: convertArrayToString(rowData),
            dataType: tableDetails?.header?.[0].dataType ?? '',
          },
        });
        tableDetails.isHomogeneous = true;
      } else {
        listTableDetails.forEach((currentDetail) => {
          currentDetail.row.forEach((obj) => tableDetails.row.push(obj));
        });
      }
    }
  } else {
    tableDetails.header = [
      {
        name: parentKey,
        dataType,
      },
    ];

    tableDetails.row.push({
      [parentKey]: {
        value,
        dataType,
      },
    });
  }

  return tableDetails;
};
